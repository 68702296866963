// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { withToggleVisibility } from '../HOC/withToggleVisibility';
import {
    DELIVERIES_TOC_OPTIONS,
    DELIVERY_CONTENT_OPTIONS,
    CONTENT_OPTIONS_ORDER,
    FE_LOCK_NAMES,
    MODAL_TYPE,
} from '@constants';
import { compose } from 'redux';
import type { UserLocksType } from '@pages/MainSearch/components/typeGuards/ResultsList.typeGuards';

import CheckboxWrapperComponent from '@reusable/CheckboxesRadioButtons/Checkboxes';

export type ContentOptions = {
    shouldContainCoverPage: boolean,
    shouldContainFullDocs: boolean,
    shouldContainResearchSummary: boolean,
    tableOfContents: string,
};

export type Option = {
    key: string,
    label: string,
    type: string,
    value: boolean,
    disabled?: boolean,
    children?: Array<Child>,
};

type Child = {
    key: string,
    value: string,
    disabled: boolean,
};

export type Options = Array<{
    key: string,
    value: boolean,
    disabled?: boolean,
    children?: Array<Child>,
}>;

type Props = {
    contentsOptions: ContentOptions,
    showDeliveryOptions: boolean,
    +handleChange: (option: Option, key: string | boolean) => void,
    +toggleDeliveryOptionsDisplay: (status: boolean) => void,
    +userLocks: UserLocksType,
    +modalType: string,
    isFullDocsDisabled: boolean,
    isSnapshotVisible: boolean,
    isSnapshotDisabled: boolean,
    popupType: string,
};

class ModalContentOptions extends React.Component<Props> {
    constructor(props) {
        super(props);
    }

    toggle = (status: boolean) => {
        this.props.toggleDeliveryOptionsDisplay(status);
    };

    formatOptions = (contentOptions: ContentOptions) => {
        const masterLockName = FE_LOCK_NAMES.deliveryIncludeResearchSummary;
        const lockName = FE_LOCK_NAMES.deliverySettingsAllOptions;

        const isLocked = this.props.userLocks[masterLockName] || this.props.userLocks[lockName];

        const options = [];

        CONTENT_OPTIONS_ORDER.forEach((option) => {
            // hide other content options for download full report from snapshot list
            const isSnapshotDownload =
                this.props.popupType === MODAL_TYPE.DOWNLOAD_SNAPSHOT_PAGE &&
                option !== DELIVERY_CONTENT_OPTIONS.snapshot;

            const isFullReportDownload =
                this.props.popupType === MODAL_TYPE.DOWNLOAD_BATCH_SCREENING_FULL_REPORT &&
                option === DELIVERY_CONTENT_OPTIONS.fullDocs;

            if (isFullReportDownload || isSnapshotDownload) {
                return;
            }

            switch (option) {
                case DELIVERY_CONTENT_OPTIONS.TOC:
                    let TOCOption = contentOptions[option];
                    let isDisabled = TOCOption === DELIVERIES_TOC_OPTIONS.none;

                    options.push({
                        key: option,
                        value: !isDisabled,
                        children: [
                            {
                                key: DELIVERIES_TOC_OPTIONS.basic,
                                value: TOCOption === DELIVERIES_TOC_OPTIONS.basic,
                                disabled: isDisabled,
                            },
                            {
                                key: DELIVERIES_TOC_OPTIONS.expanded,
                                value: TOCOption === DELIVERIES_TOC_OPTIONS.expanded,
                                disabled: isDisabled,
                            },
                        ],
                    });
                    break;
                case DELIVERY_CONTENT_OPTIONS.researchSummary:
                    options.push({
                        key: option,
                        disabled: isLocked,
                        value: contentOptions[option],
                    });
                    break;
                case DELIVERY_CONTENT_OPTIONS.snapshot:
                    options.push({
                        key: option,
                        disabled: !this.props.isSnapshotVisible || this.props.isSnapshotDisabled,
                        value:
                            this.props.isSnapshotVisible && !this.props.isSnapshotDisabled
                                ? contentOptions[option]
                                : false,
                    });

                    break;

                default:
                    options.push({
                        key: option,
                        value: contentOptions[option],
                    });
            }
        });

        return options;
    };

    handleChange = (options: Options, option: Option) => {
        let value = !option.value;

        if (option.key === DELIVERY_CONTENT_OPTIONS.TOC) {
            if (!value) {
                value = DELIVERIES_TOC_OPTIONS.none;
            } else {
                let selected = option.children && option.children.filter((entry) => entry.value);
                if (selected && selected.length) {
                    value = selected[0].key;
                } else {
                    value = DELIVERIES_TOC_OPTIONS.expanded;
                }
            }
        }
        this.props.handleChange(option, value);
    };

    handleTOCOptionChange = (parentOption: Option, option: Child) => {
        this.props.handleChange(parentOption, option.key);
    };

    componentDidMount() {
        // when userPreferences showSnapshot is false we should set shouldContainSnapshot to false
        if (!this.props.isSnapshotVisible && this.props.contentsOptions.shouldContainSnapshot) {
            const option = this.formatOptions(this.props.contentsOptions).find(
                (elem) => elem.key === DELIVERY_CONTENT_OPTIONS.snapshot
            );
            this.props.handleChange(option, false);
        }
    }

    render() {
        if (isEmpty(this.props.contentsOptions)) return null;

        const options = this.formatOptions(this.props.contentsOptions);
        const areAllChecked = options.filter((entry) => entry.value).length !== 0;
        const opened = this.props.showDeliveryOptions;
        const showWarning = !areAllChecked && this.props.popupType !== MODAL_TYPE.DOWNLOAD_RESULT_SNAPSHOT;

        return (
            <div className="popup-modal__delivery-content-options">
                <div
                    className="popup-modal__delivery-content-options__header"
                    onClick={() => this.toggle(!opened)}
                    data-testid="toggle-content-options-display"
                >
                    <FormattedMessage id={`PopupModal.body.contentOptions.${this.props.modalType}.accordion`} /> &nbsp;
                    {opened ? <span className="icon la-TriangleUp" /> : <span className="icon la-TriangleDown" />}
                </div>
                <div className="popup-modal__delivery-content-options__body">
                    {showWarning && (
                        <span
                            className="popup-modal__delivery-content-options__body__warning"
                            data-testid="deselected-error-message"
                        >
                            <FormattedMessage id="PopupModal.body.contentOptions.warning" />
                        </span>
                    )}
                    {opened ? (
                        <CheckboxWrapperComponent
                            options={options}
                            className={`${
                                showWarning
                                    ? 'popup-modal__delivery-content-options__body__options-container__warning'
                                    : ''
                            } popup-modal__delivery-content-options__body__options-container`}
                            onSubCategoriesChange={this.handleTOCOptionChange}
                            onChange={this.handleChange}
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}

//For testing purposes
export const ModalContentOptionsCls = ModalContentOptions;

export default (compose(injectIntl, withToggleVisibility)(ModalContentOptions): React.AbstractComponent<Props>);
